import React from 'react';
import './App.scss';
import Routes from './routes/routes';

function App() {
  return (
    <div className="App bg-black">
      <Routes ></Routes>
    </div>
  );
}

export default App;